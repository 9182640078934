.icon-close {
  position: relative;
  margin-bottom: 0;
  transition: opacity 0.5s ease;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    content: " ";
    position: absolute;
    top: -14px;
    left: -2px;
    height: 30px;
    width: 4px;
    background-color: $black;
    opacity: 0.8;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
.search-icon-close {
  position: relative;
  margin-bottom: 0;
  transition: opacity 0.5s ease;
  display: block;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    content: " ";
    position: absolute;
    top: -14px;
    left: -2px;
    height: 24px;
    width: 3px;
    background-color: #222;
    opacity: 0.8;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

