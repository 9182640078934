@import "../variables/base";

@mixin media($size: false, $max-width: false, $min-width: false) {

  @if $size == desktop {
    @media (min-width: $desktop-breakpoint) {
      @content;
    }
  } @else if $size == tablet {
    @media (min-width: $tablet-breakpoint) {
      @content;
    }
  } @else if $size == iphone {
    @media (max-width: $iphone-breakpoint) {
      @content;
    }
  } @else if $size == mobile {
    @media (max-width: $mobile-breakpoint) {
      @content;
    }
  } @else if $max-width != false {
    @media (max-width: $max-width) {
      @content;
    }
  } @else if $min-width != false {
    @media (min-width: $min-width) {
      @content;
    }
  } @else {
    @media (min-width: $size) {
      @content;
    }
  }
}
