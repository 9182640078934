button {
    &.icon {
        background-color: $white;
        border: 1px solid $black;
        position: relative;
        vertical-align: top;
        padding: 0;
        float: right;
        clear: both;
        &[disabled="disabled"],
        &[disabled] {
            background-color: $main-blue;
        }

        &:after,
        &:before {
            background: $white;
            border: 1px solid $black;
            content: '';
            position: absolute;
        }
        &:hover,
        &:hover:after,
        &:hover:before {
            background-color: $main-blue;
        }

        &[disabled]:hover,
        &[disabled]:after,
        &[disabled]:before {
            background-color: $main-blue;
            cursor: not-allowed;
        }
    }
    &.heart {
        border-radius: 100%;
        height: 14px;
        margin-right: 12px;
        width: 15px;

        &:after {
            border-radius: 100%;
            height: 13px;
            left: 11px;
            top: -1px;
            width: 12px;
        }
        &:before {
            z-index: 1;
            border-left: none;
            border-top: none;
            height: 13px;
            left: 3px;
            top: 5px;
            width: 16px;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                    transform: rotate(45deg);
        }
    }
    &.isFav {
        &:after,
        &:before,
        &.icon {
            background-color: $main-blue;
            pointer-events: none;
        }
    }  
}