.icon-search{
    position: relative;
    margin-bottom: 0;
    transition: opacity 0.5s ease;
    display: block;
    

    &:before{
        content: "";
        pointer-events: none;
        width: 12px;
        height: 12px;
        top: 0px;
        left: 8px;
        border-radius: 50%;
        position: absolute;
        transform: translateX(-50%);
        border: 2px solid;
    }
    
    &:after{
        content: " ";
        position: absolute;
        top: 11px;
        left: 15px;
        height: 11px;
        width: 2px;
        background-color: $black;
        transform: rotate(-45deg);
    }
}