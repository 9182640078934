$base-size: 7.6px;

.icon-user{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    font-style: normal;
    color: currentColor;
    text-align: left;
    text-indent: -9999px;
    direction: ltr;
    height: $base-size;
    width: $base-size * 2.5;
    border-top-left-radius: $base-size * 2;
    border-top-right-radius: $base-size * 2;
    margin: 18px 1px 2px;
    border: 2px solid;
    border-bottom: 0;

    &:before{
        content: '';
        pointer-events: none;
        width: $base-size * 1.2;
        height: $base-size * 1.2;
        top: -$base-size * 2.25;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border: 2px solid;
    }
    
    &:after{
        content: '';
        pointer-events: none;
    }
}