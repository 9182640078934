.footer {
  background: $grey-3;
  padding-top: $gutter * 0.6;
  padding-bottom: $gutter * 0.05;
    border-top: 1px solid $grey-2;

  .footer-copyright {
    margin-top: 10px;
    line-height: 1;
    &:focus {
      outline: 3px solid #ffdd00;
    }
    @include media(mobile){
      text-align: center;
    }
  }
.footer-logo{
  @include media(mobile){
    text-align: center;
  }
}
  .footer-description {
    font-size: 14px;
  }

  .footer-logotype {
    &:focus {
      outline: 3px solid #ffdd00;
    }
    @include media(tablet) {
      float: right;
      vertical-align: middle;
    }
    img {
      width: auto;
      height: auto;
    }
  }
}
